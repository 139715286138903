import React, { useEffect, useState } from "react";
import { Drawer, Divider, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Text } from "@shopify/polaris";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import {
  GETTING_STARTED_STEPS,
  SYNC_YOUR_FIRST_LISTING_STEPS,
  RIGHT_DRAWER_WIDTH,
  USER_GETTING_STARTED_STEPS,
  NEW_RETAILS_PRODUCTS_STEPS,
  NEW_CUSTOMIZABLE_PRODUCT_STEPS,
  PRE_PRODUCTION_STEPS,
  PRODUCTION_STEPS,
  GETTING_STARTED_ENTERPRISE_ADMIN_STEPS,
} from "../../constants/dashboard";
import { updateCurrentActiveStep } from "../../actions/dashboard";
import GettingStarted from "../../pages/Dashboard/GettingStarted";
import ConnectChannelStep from "./ConnectChannelStep";
import AddPriceTableStep from "./AddPriceTableStep";
import CreateBlankProductStaep from "./CreateBlankProductStaep";
import SyncYourListing from "../../pages/Dashboard/SyncYourListing";
import SyncOrder from "./SyncOrder";
import GettingStartedUser from "../../pages/Dashboard/GettingStartedUser";
import SetUpBilling from "./SetUpBilling";
import DesignFirstProduct from "./DesignFirstProduct";
import AddProductToStore from "./AddProductToStore";
import AddIntegration from "./AddIntegration";
import SyncUnsyncedOrders from "./SyncUnsyncedOrders";
import NewRetailProducts from "../../pages/Dashboard/NewRetailProducts";
import NewCustomizableProduct from "../../pages/Dashboard/NewCustomizableProduct";
import EditYourBlankProduct from "./EditYourBlankProduct";
import PreProduction from "../../pages/Dashboard/PreProduction";
import Production from "../../pages/Dashboard/Production";
import PrinterApp from "./PrinterApp";
import DirectPrintingApp from "./DirectPrintingApp";
import BatchingItems from "./BatchingItems";
import DownloadingBarcodes from "./DownloadingBarcodes";
import PrintFirstProduct from "./PrintFirstProduct";
import QualityControl from "./QualityControl";
import ShippingLabel from "./ShippingLabel";
import NewPurchaseOrder from "./NewPurchaseOrder";
import EcommerceIntegrations from "./EcommerceIntegrations";
import AddShippingAddress from "./AddShippingAddress";
import GettingStartedEnterpriseAdmin from "../../pages/Dashboard/GettingStartedEnterpriseAdmin";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  height: 70,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

export default function RightDrawer({ open }) {
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState(2);

  const currentActiveStep = useSelector(
    (state) => state?.dashboard?.currentActiveStep
  );

  useEffect(() => {
    setCurrentTab(2);
  }, [currentActiveStep]);

  let STEPS = {};
  let RenderedComponent;

  if (
    Object.keys(GETTING_STARTED_ENTERPRISE_ADMIN_STEPS).includes(
      currentActiveStep
    )
  ) {
    STEPS = GETTING_STARTED_ENTERPRISE_ADMIN_STEPS;
    RenderedComponent = GettingStartedEnterpriseAdmin;
  }
  if (Object.keys(GETTING_STARTED_STEPS).includes(currentActiveStep)) {
    STEPS = GETTING_STARTED_STEPS;
    RenderedComponent = GettingStarted;
  }
  if (Object.keys(SYNC_YOUR_FIRST_LISTING_STEPS).includes(currentActiveStep)) {
    STEPS = SYNC_YOUR_FIRST_LISTING_STEPS;
    RenderedComponent = SyncYourListing;
  }
  if (Object.keys(USER_GETTING_STARTED_STEPS).includes(currentActiveStep)) {
    STEPS = USER_GETTING_STARTED_STEPS;
    RenderedComponent = GettingStartedUser;
  }
  if (Object.keys(NEW_RETAILS_PRODUCTS_STEPS).includes(currentActiveStep)) {
    STEPS = NEW_RETAILS_PRODUCTS_STEPS;
    RenderedComponent = NewRetailProducts;
  }
  if (Object.keys(NEW_CUSTOMIZABLE_PRODUCT_STEPS).includes(currentActiveStep)) {
    STEPS = NEW_CUSTOMIZABLE_PRODUCT_STEPS;
    RenderedComponent = NewCustomizableProduct;
  }
  if (Object.keys(PRE_PRODUCTION_STEPS).includes(currentActiveStep)) {
    STEPS = PRE_PRODUCTION_STEPS;
    RenderedComponent = PreProduction;
  }
  if (Object.keys(PRODUCTION_STEPS).includes(currentActiveStep)) {
    STEPS = PRODUCTION_STEPS;
    RenderedComponent = Production;
  }

  const step = STEPS[currentActiveStep];

  const handleDrawerClose = () => {
    dispatch(updateCurrentActiveStep(null));
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {}}
      hideBackdrop
      ModalProps={{
        keepMounted: true,
      }}
      variant="persistent"
    >
      <div style={{ width: RIGHT_DRAWER_WIDTH }}>
        {currentTab === 1 && (
          <>
            <DrawerHeader>
              <div></div>
              <IconButton onClick={handleDrawerClose}>
                <CloseIcon />
              </IconButton>
            </DrawerHeader>
            <Divider />

            <RenderedComponent />
          </>
        )}

        {currentTab === 2 && (
          <>
            <DrawerHeader>
              <div>
                <IconButton onClick={() => setCurrentTab(1)}>
                  <KeyboardBackspaceIcon />
                </IconButton>
                <span style={{ fontSize: 14, marginLeft: 5 }}>
                  View all tasks
                </span>
              </div>
              <IconButton onClick={handleDrawerClose}>
                <CloseIcon />
              </IconButton>
            </DrawerHeader>
            <Divider />
            <div style={{ padding: 20 }}>
              <Text variant="headingMd" as="h2">
                {step?.title}
              </Text>

              {["1", "5", "9", "14"].includes(step?.key) && (
                <ConnectChannelStep />
              )}
              {["2", "6", "18"].includes(step?.key) && <AddPriceTableStep />}
              {["3", "13"].includes(step?.key) && <CreateBlankProductStaep />}
              {["17"].includes(step?.key) && <SyncOrder />}
              {["8"].includes(step?.key) && <SetUpBilling />}
              {["10", "16"].includes(step?.key) && <DesignFirstProduct />}
              {["11", "17"].includes(step?.key) && <AddProductToStore />}
              {["11_5"].includes(step?.key) && <AddShippingAddress />}
              {["12"].includes(step?.key) && <AddIntegration />}
              {["15", "9_5"].includes(step?.key) && <SyncUnsyncedOrders />}
              {["19"].includes(step?.key) && <EditYourBlankProduct />}
              {["21"].includes(step?.key) && <PrinterApp />}
              {["22"].includes(step?.key) && <DirectPrintingApp />}
              {["23"].includes(step?.key) && <BatchingItems />}
              {["24"].includes(step?.key) && <DownloadingBarcodes />}
              {["25"].includes(step?.key) && <PrintFirstProduct />}
              {["26"].includes(step?.key) && <QualityControl />}
              {["27"].includes(step?.key) && <ShippingLabel />}
              {["20_5"].includes(step?.key) && <NewPurchaseOrder />}
              {["13_5"].includes(step?.key) && <EcommerceIntegrations />}
            </div>
          </>
        )}
      </div>
    </Drawer>
  );
}
