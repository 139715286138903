import { Row, Col } from "reactstrap";
import { Typography } from "@mui/material";
import useTheme from "../../../helpers/useTheme";

const Footer = () => {
  const { enterprise } = useTheme();
  return (
    <footer className="footer">
      <div className="container-fluid">
        <Row>
          <Col sm={6}>
            <Typography>
              {new Date().getFullYear()} © {enterprise?.name || "Riverr"}.
            </Typography>
          </Col>
          <Col sm={6}>
            <div className="text-sm-right d-none d-sm-block"></div>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
