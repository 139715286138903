import { ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import {
  getUID,
  getDomainHostname,
  httpLink,
  getMockUID,
  getMockDomainHostname,
} from "../api/ajax";

const authLink = setContext((_, { headers }) => {
  const uid = getUID();
  const domainHostname = getDomainHostname();
  const mockUid = getMockUID();
  const mockDomainHostname = getMockDomainHostname();

  headers = {
    ...headers,
    Accept: "application/json",
    "domain-hostname": domainHostname,
  };

  if (uid && uid !== "undefined") {
    headers = {
      ...headers,
      "x-uid": uid,
    };
  }

  if (mockUid && mockUid !== "undefined") {
    headers = {
      ...headers,
      "x-uid": mockUid,
      "mock-uid": mockUid,
    };
  }

  if (mockDomainHostname && mockDomainHostname !== "undefined") {
    headers = {
      ...headers,
      "domain-hostname": mockDomainHostname,
      "mock-domain-hostname": mockDomainHostname,
    };
  }

  return {
    headers,
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
