import { keys, update, isEmpty } from "lodash";

import {
  REQUEST_PURCHASE_ORDERS_SUCCESS,
  REQUEST_PURCHASE_ORDER_ITEMS_SUCCESS,
  UPDATE_PURCHASE_ORDER_PRODUCT,
  REQUEST_PRODUCT_OF_GTIN_SUCCESS,
  CREATE_PURCHASE_ORDER_SUCCESS,
  MARK_AS_RECEIVED_SUCCESS,
  REQUEST_NEW_PURCHASE_ORDER_ITEMS_SUCCESS,
  UPDATE_PURCHASE_ORDER_PRODUCT_QUANTITY,
  REQUEST_PURCHASE_ORDER_ITEMS_INVENTORY_SUCCESS,
  REQUEST_REMOVE_PURCHASE_ORDER_ITEM,
} from "../../constants/actionTypes";

const INITIAL_STATE = {
  purchaseOrders: [],
  products: [],
  inventory: {},
  productsList: [],
  importedBatchIds: [],
};

export default function purchaseOrders(state = INITIAL_STATE, action) {
  const {
    purchaseOrders = [],
    products = [],
    inventory = {},
  } = action?.payload?.data || {};

  switch (action.type) {
    case REQUEST_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        purchaseOrders,
      };

    case REQUEST_NEW_PURCHASE_ORDER_ITEMS_SUCCESS:
      const newProductsList = [...state.productsList];
      const newProductsPayload = action.payload.productsList || [];
      const fetchedBtachId = action.payload.batchId;

      newProductsPayload.forEach((newProduct) => {
        const existingProductIndex = newProductsList.findIndex(
          (p) => p.id === newProduct.id
        );

        if (existingProductIndex !== -1) {
          newProductsList[existingProductIndex].quantity += newProduct.quantity;
          newProductsList[existingProductIndex].stock = {
            ...newProductsList[existingProductIndex].stock,
            required: newProductsList[existingProductIndex].quantity,
          };
        } else {
          newProductsList.push(newProduct);
        }
      });

      return {
        ...state,
        productsList: newProductsList,
        importedBatchIds: fetchedBtachId
          ? [...state.importedBatchIds, fetchedBtachId]
          : state.importedBatchIds,
      };

    case UPDATE_PURCHASE_ORDER_PRODUCT_QUANTITY:
      const { productId, quantity } = action.payload;
      const updatedProductsList = state.productsList.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity,
            stock: { ...product.stock, required: quantity },
          };
        }
        return product;
      });

      return {
        ...state,
        productsList: updatedProductsList,
      };

    case REQUEST_PURCHASE_ORDER_ITEMS_SUCCESS:
      return {
        ...state,
        products,
        inventory,
      };

    case REQUEST_PURCHASE_ORDER_ITEMS_INVENTORY_SUCCESS:
      const updatedInventory = {
        ...state.inventory,
        ...Object.keys(inventory).reduce((acc, blankProductId) => {
          acc[blankProductId] = {
            ...(state.inventory[blankProductId] || {}),
            ...inventory[blankProductId],
          };
          return acc;
        }, {}),
      };

      return {
        ...state,
        inventory: updatedInventory,
      };

    case REQUEST_PRODUCT_OF_GTIN_SUCCESS:
      let newInv = state?.inventory || {};
      const inventoryPayload = action?.payload?.data?.inventory || {};

      keys(inventoryPayload).forEach((bpId) => {
        update(newInv, `${bpId}`, (n) =>
          isEmpty(n)
            ? { ...inventoryPayload[bpId] }
            : { ...n, ...inventoryPayload[bpId] }
        );
      });

      return {
        ...state,
        inventory: newInv,
      };

    case UPDATE_PURCHASE_ORDER_PRODUCT:
      const { product: oldProduct = {}, newProduct = {} } =
        action.payload || {};
      const updatedProductsListForUpdate = state.productsList.map((product) => {
        if (product.id === oldProduct.id) {
          return {
            ...newProduct,
            stock: { ...product.stock, required: newProduct.quantity },
          };
        }
        return product;
      });

      return {
        ...state,
        productsList: updatedProductsListForUpdate,
      };
    case REQUEST_REMOVE_PURCHASE_ORDER_ITEM: {
      return {
        ...state,
        productsList: state.productsList.filter(
          (product) => product.supplierId !== action.payload
        ),
      };
    }

    case CREATE_PURCHASE_ORDER_SUCCESS:
      const productsAfterCreate = state?.products.filter(
        (f) => f?.supplierId !== action.payload?.supplierId
      );

      return {
        ...state,
        products: productsAfterCreate,
      };

    case MARK_AS_RECEIVED_SUCCESS:
      const afterMarkReceived = (state?.purchaseOrders || []).map((po) => {
        if (po?.id === action?.payload?.data?.purchaseOrder?.id) {
          return {
            ...po,
            received: true,
          };
        }

        return po;
      });

      return {
        ...state,
        purchaseOrders: afterMarkReceived,
      };

    default:
      return state;
  }
}
