import { keyBy } from "lodash";

import {
  REQUEST_INTEGRATIONS_SUCCESS,
  REQUEST_INTEGRATIONS_FAILURE,
  GET_SUPPLIER_CREDENTIALS_SUCCESS,
  UPDATE_CREDENTIALS_SUCCESS,
} from "../../constants/actionTypes";

const INITIAL_STATE = {
  integrations: {},
  credentials: {},
};

export default function integrations(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_INTEGRATIONS_SUCCESS:
      const integrations = action.payload?.data?.integrations || {};
      const ecommercePlatforms = integrations.filter(
        (f) => f.type === "Ecommerce"
      );

      return {
        ...state,
        integrations,
        integrationsWithKeys: keyBy(integrations, "id"),
        ecommercePlatforms,
      };

    case GET_SUPPLIER_CREDENTIALS_SUCCESS:
      const credentials = keyBy(
        action?.payload?.data?.credentials || [],
        "supplierId"
      );

      return {
        ...state,
        credentials,
      };

    case UPDATE_CREDENTIALS_SUCCESS:
      const supplier = action?.payload?.data || {};

      return {
        ...state,
        credentials: {
          ...state.credentials,
          [supplier?.supplierId]: supplier,
        },
      };

    case REQUEST_INTEGRATIONS_FAILURE:
      return { ...state, error: "Record not found" };

    default:
      return state;
  }
}
