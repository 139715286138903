import React from "react";

const AddShippingAddress = () => {
  return (
    <div style={{ paddingTop: 20 }}>
      <p>
        We will need your shipping address in order to process orders correctly
        as well as send the info to platforms such as Shopify.
      </p>
    </div>
  );
};

export default AddShippingAddress;
