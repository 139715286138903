// AppButton.jsx
import React from "react";
import { Button } from "reactstrap";
import useTheme from "../../helpers/useTheme";

const AppButton = ({
  text = "Submit",
  size = "sm",
  type = "button",
  color, // Custom background color
  children,
  textColor = "#fff", // Default text color
  className = "",
  borderColor,
  ...rest
}) => {
  const {
    // color: themeColor = {},
    // bgColor,
    // tertiaryColor,
    primaryColor,
  } = useTheme();

  // Determine the button's background color
  const buttonBackgroundColor = color || primaryColor || "primary";

  // Define the style object
  const buttonStyle = {
    fontSize: "14px",
    borderRadius: "20px",
    fontFamily: "Vastago Grotesk, sans-serif",
    color: textColor,
    backgroundColor: buttonBackgroundColor,
    borderColor: borderColor ? borderColor : buttonBackgroundColor, // To ensure border matches background
  };

  return (
    <Button
      type={type}
      size={size}
      className={className}
      style={buttonStyle}
      {...rest}
    >
      {children || text}
    </Button>
  );
};

export default AppButton;
