import { all, call, fork, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
} from "../../actions/authentication";
import { LOGIN_USER, LOGOUT_USER } from "../../constants/actionTypes";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../helpers/firebase_helper";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user } }) {
  try {
    const response = yield call(
      fireBaseBackend.loginUser,
      user.email,
      user.password,
      user.customToken
    );

    yield put(loginSuccess(response));
    window.location.href = "/dashboard";
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser() {
  try {
    const response = yield call(fireBaseBackend.logout);
    yield put(logoutUserSuccess(response));
    window.location.href = "/login";
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;
