import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function CustomizedSnackbars({
  onClose,
  open,
  alert,
  autoHideDuration,
  vertical,
  horizontal,
  children,
  keepAlive,
}) {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    onClose(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={keepAlive ? null : autoHideDuration || 6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: vertical || "bottom",
          horizontal: horizontal || "center",
        }}
      >
        {children || (
          <Alert onClose={handleClose} severity={alert.type}>
            {alert.message}
          </Alert>
        )}
      </Snackbar>
    </div>
  );
}
