import moment from "moment";
import SyncYourUnsyncedOrders from "../pages/Dashboard/GettingStarted/SyncYourUnsyncedOrders";
// import WatchThisFirst from "../pages/Dashboard/GettingStarted/WatchThisFirst";

export const RIGHT_DRAWER_WIDTH = "24vw";

const getDays = () => {
  const dates = [5, 4, 3, 2, 1, 0].map((day) =>
    moment().subtract(day, "days").format("D-M-YYYY")
  );
  let temp = {};
  dates.forEach((date) => {
    temp = {
      ...temp,
      [date]: {
        new: Math.floor(Math.random() * 100),
        shipped: Math.floor(Math.random() * 100),
      },
    };
  });
  return temp;
};

export const Dashboard_Data = {
  counts: {
    shipped: 10,
  },
  total: {
    cancelled: 1,
    new: 90,
    shipped: 7,
    total: 98,
  },
  dashboardOrders: getDays(),
  isDummyData: true,
};

export const DUMMY_PRINT_STATS = {
  "Brother GTX pro 2": {
    series: [
      {
        name: "Brother GTX pro 2",
        data: [
          1, 6, 41, 31, 7, 41, 13, 3, 21, 65, 37, 31, 28, 21, 22, 71, 27, 70,
          85, 117, 99, 40, 72, 113, 129, 159, 133, 2, 103, 5,
        ],
      },
    ],
    options: {
      chart: {
        toolbar: {
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
        },
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2022-01-12T00:00:00+05:30",
          "2022-01-09T00:00:00+05:30",
          "2022-01-07T00:00:00+05:30",
          "2022-01-06T00:00:00+05:30",
          "2022-01-05T00:00:00+05:30",
          "2022-01-04T00:00:00+05:30",
          "2022-01-03T00:00:00+05:30",
          "2021-12-31T00:00:00+05:30",
          "2021-12-30T00:00:00+05:30",
          "2021-12-29T00:00:00+05:30",
          "2021-12-28T00:00:00+05:30",
          "2021-12-27T00:00:00+05:30",
          "2021-12-23T00:00:00+05:30",
          "2021-12-22T00:00:00+05:30",
          "2021-12-21T00:00:00+05:30",
          "2021-12-20T00:00:00+05:30",
          "2021-12-17T00:00:00+05:30",
          "2021-12-16T00:00:00+05:30",
          "2021-12-15T00:00:00+05:30",
          "2021-12-14T00:00:00+05:30",
          "2021-12-13T00:00:00+05:30",
          "2021-12-11T00:00:00+05:30",
          "2021-12-10T00:00:00+05:30",
          "2021-12-09T00:00:00+05:30",
          "2021-12-08T00:00:00+05:30",
          "2021-12-07T00:00:00+05:30",
          "2021-12-06T00:00:00+05:30",
          "2021-12-04T00:00:00+05:30",
          "2021-12-03T00:00:00+05:30",
          "2021-12-02T00:00:00+05:30",
        ],
        convertedCatToNumeric: false,
      },
    },
  },
  "Brother GTX pro": {
    series: [
      {
        name: "Brother GTX pro",
        data: [
          5, 41, 33, 5, 43, 26, 5, 19, 77, 46, 32, 31, 14, 19, 42, 22, 62, 74,
          111, 73, 38, 68, 106, 105, 148, 151, 4, 126, 203,
        ],
      },
    ],
    options: {
      chart: {
        toolbar: {
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
        },
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2022-01-09T00:00:00+05:30",
          "2022-01-07T00:00:00+05:30",
          "2022-01-06T00:00:00+05:30",
          "2022-01-05T00:00:00+05:30",
          "2022-01-04T00:00:00+05:30",
          "2022-01-03T00:00:00+05:30",
          "2021-12-31T00:00:00+05:30",
          "2021-12-30T00:00:00+05:30",
          "2021-12-29T00:00:00+05:30",
          "2021-12-28T00:00:00+05:30",
          "2021-12-27T00:00:00+05:30",
          "2021-12-23T00:00:00+05:30",
          "2021-12-22T00:00:00+05:30",
          "2021-12-21T00:00:00+05:30",
          "2021-12-20T00:00:00+05:30",
          "2021-12-17T00:00:00+05:30",
          "2021-12-16T00:00:00+05:30",
          "2021-12-15T00:00:00+05:30",
          "2021-12-14T00:00:00+05:30",
          "2021-12-13T00:00:00+05:30",
          "2021-12-11T00:00:00+05:30",
          "2021-12-10T00:00:00+05:30",
          "2021-12-09T00:00:00+05:30",
          "2021-12-08T00:00:00+05:30",
          "2021-12-07T00:00:00+05:30",
          "2021-12-06T00:00:00+05:30",
          "2021-12-04T00:00:00+05:30",
          "2021-12-03T00:00:00+05:30",
          "2021-12-02T00:00:00+05:30",
        ],
        convertedCatToNumeric: false,
      },
    },
  },
  "Brother GTX pro (Copy 1)": {
    series: [
      {
        name: "Brother GTX pro (Copy 1)",
        data: [3, 21, 15, 1, 2, 12],
      },
    ],
    options: {
      chart: {
        toolbar: {
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
        },
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2022-01-05T00:00:00+05:30",
          "2022-01-04T00:00:00+05:30",
          "2022-01-03T00:00:00+05:30",
          "2021-12-23T00:00:00+05:30",
          "2021-12-20T00:00:00+05:30",
          "2021-12-16T00:00:00+05:30",
        ],
        convertedCatToNumeric: false,
      },
    },
  },
};

// export const GETTING_STARTED_STEPS = {
//   1: {
//     key: "1",
//     mappingKey: "shopCreated",
//     title: "Connect your first channel",
//     content:
//       "The magic begins when you sync your channels with us. Don’t worry, this takes a matter of seconds.",
//     buttonText: "Add Channel",
//     redirectRoute: "/shops",
//     imageSrc:
//       "https://firebasestorage.googleapis.com/v0/b/riverr-enterprise-user.appspot.com/o/static%2Fmarketplace.png?alt=media&token=96b769db-7f07-4630-8baa-f8954a5f6742",
//   },
//   2: {
//     key: "2",
//     mappingKey: "pricingTableCreated",
//     title: "Create a price table",
//     content:
//       "Price tables allow you to set pricing for a variety of print methods, including screen print, embroidery, DTG and sublimation.",
//     buttonText: "Add Price Table",
//     redirectRoute: "/pricing-table",
//     imageSrc:
//       "https://cdn.shopify.com/shopifycloud/shopify/assets/admin/home/onboarding/detail-images/add-product-task-0925730a3c6903aee503d96b4bde717dddbfa7627da910553186100f6087a566.svg",
//   },
//   3: {
//     key: "3",
//     mappingKey: "manualProductCreated",
//     title: "Create your first customizable product",
//     content:
//       "Product customization has to be straightforward and easy to use for it to be a good experience for customers. We’ve streamlined this process for you with ready to use mockups and swatches for products from major distributors.",
//     buttonText: "Add Product",
//     redirectRoute: "/all-added-blanks",
//     imageSrc:
//       "https://cdn.shopify.com/shopifycloud/shopify/assets/admin/home/onboarding/detail-images/add-product-task-0925730a3c6903aee503d96b4bde717dddbfa7627da910553186100f6087a566.svg",
//   },
//   4: {
//     key: "4",
//     mappingKey: "customizeButton",
//     title: "Enable the customization button in Shopify",
//     content:
//       "If you are using Shopify, you will have a one-time task of adding the “Customization” button to your product page and removing the “Add to Cart” and “Buy Now” buttons.",
//     buttonText: "Watch Video Guide",
//     link: "https://vimeo.com/741787823",
//     imageSrc:
//       "https://cdn.shopify.com/shopifycloud/shopify/assets/admin/home/onboarding/detail-images/add-product-task-0925730a3c6903aee503d96b4bde717dddbfa7627da910553186100f6087a566.svg",
//   },
// };

export const SYNC_YOUR_FIRST_LISTING_STEPS = {
  5: {
    key: "5",
    title: "Connect your first channel",
    content:
      "The magic begins when you sync your channels with us. Don’t worry, this takes a matter of seconds.",
    buttonText: "Add Channel",
    redirectRoute: "/shops",
    imageSrc:
      "https://firebasestorage.googleapis.com/v0/b/riverr-enterprise-user.appspot.com/o/static%2Fmarketplace.png?alt=media&token=96b769db-7f07-4630-8baa-f8954a5f6742",
    rankOrder: 1,
  },
  6: {
    key: "6",
    title: "Create a price table",
    content:
      "Price tables allow you to set pricing for a variety of print methods, including screen print, embroidery, DTG and sublimation.",
    buttonText: "Add Price Table",
    redirectRoute: "/pricing-table",
    rankOrder: 2,
  },
  7: {
    key: "7",
    title: "Find and sync a listing in one of your unsynced orders",
    content:
      "Price tables allow you to set pricing for a variety of print methods, including screen print, embroidery, DTG and sublimation.",
    buttonText: "Add Price Table",
    redirectRoute: "/pricing-table",
    rankOrder: 3,
  },
};

export const USER_GETTING_STARTED_STEPS = {
  // "7_5": {
  //   key: "7_5",
  //   mappingKey: "watchThisFirst",
  //   title: "Watch this first!",
  //   contentTypeIsNode: true,
  //   content: <WatchThisFirst />,
  //   buttonText: "Watch this first!",
  //   redirectRoute: "/",
  //   rankOrder: 1,
  // },
  8: {
    key: "8",
    mappingKey: "billingCreated",
    title: "Set Up Billing",
    content:
      "We want to get started fulfilling for you today! In order to do that, we will simply need a credit card or a bank account to get started.",
    buttonText: "Set Up Billing",
    redirectRoute: "/wallet",
    rankOrder: 2,
  },
  9: {
    key: "9",
    mappingKey: "shopCreated",
    title: "Connect your first channel",
    content:
      "The magic begins when you sync your channels with us. Don’t worry, this takes a matter of seconds.",
    buttonText: "Add Channel",
    redirectRoute: "/shops",
    rankOrder: 3,
  },
  "9_5": {
    key: "9_5",
    mappingKey: "orderSynced",
    title: "Sync Your Unsynced Orders",
    contentTypeIsNode: true,
    content: <SyncYourUnsyncedOrders />,
    // "Now that you have connected your channel, we need to sync the products in the unsynced orders.",
    buttonText: "Sync orders",
    redirectRoute: "/orders?filter=unsynced",
    rankOrder: 4,
  },
  10: {
    key: "10",
    mappingKey: "createProduct",
    title: "Create your first product",
    contentTypeIsNode: true,
    content: <SyncYourUnsyncedOrders />,
    // "Now that you have connected your channel, we need to sync the products in the unsynced orders.",
    buttonText: "Create your first product",
    redirectRoute: "/products/new-product",
    rankOrder: 5,
  },
  // 10: {
  //   key: "10",
  //   mappingKey: "manualProductCreated",
  //   title: "Design Your First Product",
  //   content: "This is where the magic begins to happen.",
  //   buttonText: "Design Your First Product",
  //   redirectRoute: "/products/new-product",
  //   rankOrder: 4,
  // },
  // 11: {
  //   key: "11",
  //   mappingKey: "productAddedToShop",
  //   title: "Add Products To Store",
  //   content:
  //     "Now you have the power to send your newly created products to any (Or every!) channel you’ve connected in seconds.",
  //   buttonText: "Add Products To Store",
  //   redirectRoute: "/products",
  //   rankOrder: 5,
  // },
};

export const GETTING_STARTED_STEPS = {
  "11_5": {
    key: "11_5",
    mappingKey: "shippingAddressAdded",
    title: "Add your Shipping Address",
    content:
      "We will need your shipping address in order to process orders correctly as well as send the info to platforms such as Shopify.",
    buttonText: "Add Address",
    redirectRoute: "/addresses",
    rankOrder: 1,
  },
  12: {
    key: "12",
    mappingKey: "integrationsVisited",
    title: "Add Your Integrations",
    content:
      "Before going any further, you will need to add any necessary integrations that you will be using to fulfill your orders.",
    buttonText: "Add Integrations",
    redirectRoute: "/integrations",
    rankOrder: 1,
  },
  13: {
    key: "13",
    mappingKey: "blankProductCreated",
    title: "Create your first product",
    content:
      "Product customization has to be straightforward and easy to use for it to be a good experience for customers.",
    buttonText: "Add Product",
    redirectRoute: "/all-added-blanks",
    rankOrder: 2,
  },
  "13_5": {
    key: "13_5",
    mappingKey: "ecommerceIntegrationsSetup",
    title: "Set Up Your Ecommerce Integrations",
    content:
      "Set up your integrations with all of the ecommerce platforms that you would like to work with.",
    buttonText: "Set Up",
    redirectRoute: "/shops",
    rankOrder: 3,
  },
  14: {
    key: "14",
    mappingKey: "shopCreated",
    title: "Connect your first channel",
    content:
      "The magic begins when you sync your channels with us. Don’t worry, this takes a matter of seconds.",
    buttonText: "Add Channel",
    redirectRoute: "/shops",
    rankOrder: 4,
  },
  15: {
    key: "15",
    mappingKey: "orderSynced",
    title: "Sync Your Unsynced Orders",
    contentTypeIsNode: true,
    content: <SyncYourUnsyncedOrders />,
    // "Now that you have connected your channel, we need to sync the products in the unsynced orders.",
    buttonText: "Sync orders",
    redirectRoute: "/unsynced-orders",
    rankOrder: 5,
  },
};

export const GETTING_STARTED_ENTERPRISE_ADMIN_STEPS = {
  "11_5": {
    key: "11_5",
    mappingKey: "shippingAddressAdded",
    title: "Add your Shipping Address",
    content:
      "We will need your shipping address in order to process orders correctly as well as send the info to platforms such as Shopify.",
    buttonText: "Add Address",
    redirectRoute: "/addresses",
    rankOrder: 1,
  },
  12: {
    key: "12",
    mappingKey: "integrationsVisited",
    title: "Add Your Integrations",
    content:
      "Before going any further, you will need to add any necessary integrations that you will be using to fulfill your orders.",
    buttonText: "Add Integrations",
    redirectRoute: "/integrations",
    rankOrder: 2,
  },
  13: {
    key: "13",
    mappingKey: "blankProductCreated",
    title: "Create your first product",
    content:
      "Product customization has to be straightforward and easy to use for it to be a good experience for customers.",
    buttonText: "Add Product",
    redirectRoute: "/all-added-blanks",
    rankOrder: 3,
  },
};

export const NEW_RETAILS_PRODUCTS_STEPS = {
  16: {
    key: "16",
    mappingKey: "manualProductCreated",
    title: "Create a New Product",
    content: "This is where the magic begins to happen.",
    buttonText: "Design Your First Product",
    redirectRoute: "/products/new-product",
    rankOrder: 1,
  },
  17: {
    key: "17",
    mappingKey: "sentProductToChannel",
    title: "Send Product to channel",
    content:
      "Now you have the power to send your newly created products to any (Or every!) channel you’ve connected in seconds.",
    buttonText: "Send Product to channel",
    redirectRoute: "/products",
    rankOrder: 2,
  },
};

export const NEW_CUSTOMIZABLE_PRODUCT_STEPS = {
  18: {
    key: "18",
    mappingKey: "pricingTableCreated",
    title: "Create a price table",
    content:
      "Price tables allow you to set pricing for a variety of print methods, including screen print, embroidery, DTG and sublimation.",
    buttonText: "Add Price Table",
    redirectRoute: "/pricing-table",
    rankOrder: 1,
  },
  19: {
    key: "19",
    mappingKey: "customizableProductCreated",
    title: "Edit Your Blank Product",
    content:
      "In order to add a customizable product to your Shopify channel, follow these steps:",
    buttonText: "Edit Your Blank Product",
    redirectRoute: "/inventory-products/new/manual-product",
    rankOrder: 2,
  },
  20: {
    key: "20",
    mappingKey: "customizeButton",
    title: "Enable the customization button in Shopify",
    content:
      "If you are using Shopify, you will have a one-time task of adding the “Customization” button to your product page and removing the “Add to Cart” and “Buy Now” buttons.",
    buttonText: "Watch Video Guide",
    link: "https://vimeo.com/741787823",
    rankOrder: 3,
  },
};

export const PRE_PRODUCTION_STEPS = {
  "20_5": {
    key: "20_5",
    mappingKey: "purchaseOrderCreated",
    title: "Create your first Purchase Order",
    content:
      "We will magically create your purchase orders for you with the click of a few buttons.",
    buttonText: "Learn More",
    redirectRoute: "/new-purchase-order",
    rankOrder: 1,
  },
  21: {
    key: "21",
    mappingKey: "printerAppDownloaded",
    title: "Download the printer app",
    content: "Our printer applications can do it all",
    buttonText: "Learn More",
    redirectRoute: "/integrations",
    rankOrder: 2,
  },
  22: {
    key: "22",
    mappingKey: "directPrintingAppDownloaded",
    title: "Download the direct print app",
    content: "This will save your packaging and shipping time significantly.",
    buttonText: "Learn More",
    redirectRoute: "/integrations",
    rankOrder: 3,
  },
  23: {
    key: "23",
    mappingKey: "batchingItems",
    title: "Batching items",
    content: "You can find information about batching here.",
    buttonText: "Learn More",
    redirectRoute: "/items",
    rankOrder: 4,
  },
  24: {
    key: "24",
    mappingKey: "downloadBarcodes",
    title: "Downloading barcodes",
    content: "You can download the barcodes from the batch page.",
    buttonText: "Learn More",
    redirectRoute: "/batches",
    rankOrder: 5,
  },
};

export const PRODUCTION_STEPS = {
  25: {
    key: "25",
    mappingKey: "printedFirstProduct",
    title: "Print your first product",
    content:
      "Need information on how to use the barcodes to print? Check out our documentation.",
    buttonText: "Learn More",
    redirectRoute: "/dashboard",
    rankOrder: 1,
  },
  26: {
    key: "26",
    mappingKey: "shipNowClicked",
    title: "Complete Quality Control",
    content: "Intro to Quality Control",
    buttonText: "Learn More",
    redirectRoute: "/dashboard",
    rankOrder: 2,
  },
  27: {
    key: "27",
    mappingKey: "packagingSlipAndShippingLabelClicked",
    title: "Print the Shipping Label and Packing Slip",
    content: "Shipping Label Options",
    buttonText: "Learn More",
    redirectRoute: "/dashboard",
    rankOrder: 3,
  },
};
