import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  signInWithCustomToken,
} from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { LOCALSTORAGE_KEYS } from "../constants/users";

const isTestEnv = false;

const API_KEY = isTestEnv
  ? "AIzaSyB46-j4jz_9SAW9jb26jvxn7XpW7lAS90E"
  : "AIzaSyBBelgl_vhZiTnUvxL5U_TAD9vopqQbC8I";
const PROJECT_NAME = isTestEnv
  ? "riverr-enterprise-test"
  : "riverr-enterprise-user";
const MESSAGING_SENDER_ID = isTestEnv ? "689886529820" : "1032772938785";
const APP_ID = isTestEnv
  ? "1:689886529820:web:2bc41f55b64f36bb9092d9"
  : "1:1032772938785:web:b3a609881b413fdac44760";
const MEASUREMENT_ID = isTestEnv ? "G-1VE5XH299X" : "G-CLLNW3M6EB";

const firebaseConfig = {
  apiKey: API_KEY,
  authDomain: `${PROJECT_NAME}.firebaseapp.com`,
  databaseURL: `https://${PROJECT_NAME}.firebaseio.com`,
  projectId: PROJECT_NAME,
  storageBucket: `${PROJECT_NAME}.appspot.com`,
  messagingSenderId: MESSAGING_SENDER_ID,
  appId: APP_ID,
  measurementId: MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp);

class FirebaseAuthBackend {
  constructor() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        localStorage.setItem("authUser", JSON.stringify(user));
      } else {
        localStorage.removeItem("authUser");
      }
    });
  }

  registerUser = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      return userCredential.user;
    } catch (error) {
      throw this._handleError(error);
    }
  };

  loginUser = async (email, password, customToken = null) => {
    try {
      // Ensure any previous session is cleared before login
      await this.logout();

      if (customToken) {
        const userCredential = await signInWithCustomToken(auth, customToken);
        localStorage.setItem("authUser", JSON.stringify(userCredential.user));
        return userCredential.user;
      }

      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      localStorage.setItem("authUser", JSON.stringify(userCredential.user));
      return userCredential.user;
    } catch (error) {
      throw this._handleError(error);
    }
  };

  forgetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      return true;
    } catch (error) {
      throw this._handleError(error);
    }
  };

  logout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem("authUser");
      localStorage.removeItem("riverrTheme");
      localStorage.removeItem(LOCALSTORAGE_KEYS.mockUser);
      return true;
    } catch (error) {
      throw this._handleError(error);
    }
  };

  setLoggedInUser = async (user) => {
    localStorage.setItem("authUser", JSON.stringify(user));

    try {
      const userDoc = await getDoc(doc(db, "users", user.uid));
      if (userDoc.exists()) {
        localStorage.setItem("userDoc", JSON.stringify(userDoc.data()));
      } else {
        localStorage.removeItem("userDoc");
      }
    } catch (error) {
      console.error("Error fetching user document:", error);
    }
  };

  getAuthenticatedUser = () => {
    return JSON.parse(localStorage.getItem("authUser")) || null;
  };

  editProfileAPI = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      return userCredential.user;
    } catch (error) {
      throw this._handleError(error);
    }
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    return error.message;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 */
const initFirebaseBackend = () => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend();
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

initFirebaseBackend();

export {
  firebaseConfig,
  getFirebaseBackend,
  initFirebaseBackend,
  auth,
  db as projectFirestore,
  storage as projectStorage,
  functions as projectFunctions,
};
