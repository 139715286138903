import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { REGISTER_USER } from "../../constants/actionTypes";
import {
  registerUserSuccessful,
  registerUserFailed,
} from "../../actions/authentication";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../helpers/firebase_helper";
import { createUser } from "../../api/user";

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  try {
    const response = yield call(
      fireBaseBackend.registerUser,
      user.email,
      user.password
    );

    yield call(createUser, user);

    yield put(registerUserSuccessful(response));
    window.location.reload();
  } catch (error) {
    yield call(fireBaseBackend.logout);
    yield put(registerUserFailed(error));
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser);
}

function* accountSaga() {
  yield all([fork(watchUserRegister)]);
}

export default accountSaga;
