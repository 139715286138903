// import useTheme from "../../helpers/useTheme";

const PreLoader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner-chase">
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
        </div>
      </div>
    </div>
  );
};

export default PreLoader;
